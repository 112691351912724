import React from "react";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import configureStore from "./store/store";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback, { ErrorHandler } from "./components/ErrorFallback";
import ReactDOM from "react-dom/client";

const root = ReactDOM.createRoot(document.getElementById("root")!);

const store = configureStore();

if (sessionStorage.getItem("bcc_access_token") || (window.location.hostname === "localhost" && sessionStorage.getItem("access_token"))) {
  root.render(
    <Provider store={store}>
      <React.StrictMode>
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={ErrorHandler}>
          <App />
        </ErrorBoundary>
      </React.StrictMode>
    </Provider>,
  );
} else {
  console.error("No access_token from bcc, waiting on authentication.");
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
